import "styles/pages/page-ambassadors.scss";
import React from "react";
import { graphql } from "gatsby";
import Layout from "components/Layout";
import Seo from "components/Seo";
import SectionTitle from "components/SectionTitle";
import Breadcrumbs from "components/Breadcrumbs";
import CollapseTile from "components/CollapseTile";

const AmbassadorsPage = ({ pageContext, data }) => {
   const seo = pageContext.seo;
   const ambassadors = data.allWpAmbassador.nodes;
   return (
      <Layout>
         <Seo
            title={seo.title ? seo.title : pageContext.title}
            description={seo.metaDesc}
         />
         <Breadcrumbs currentLocationLabel={pageContext.title} />
         <div className="container">
            <section className="ambassadors-page">
               <SectionTitle title={pageContext.title} />
               <div
                  className="ambassadors-page__text"
                  dangerouslySetInnerHTML={{ __html: pageContext.data.text }}
               />
               <div className="ambassadors-page__grid-row">
                  {ambassadors.map((obj, index) => {
                     const item = obj.cptAmbassadors;
                     return (
                        <div className="ambassadors-page__tile" key={index}>
                           <CollapseTile
                              logo={item.image}
                              name={item.name}
                              subtitle={item.title}
                              webpage={item.webpage}
                              email={item.email}
                              phones={item.phones}
                           />
                        </div>
                     );
                  })}
               </div>
            </section>
         </div>
      </Layout>
   );
};

export const query = graphql`
   query {
      allWpAmbassador {
         nodes {
            cptAmbassadors {
               email
               image {
                  sourceUrl
                  altText
               }
               name
               phones {
                  phone
               }
               title
            }
         }
      }
   }
`;

export default AmbassadorsPage;
